import Link from 'next/link'

export default function LinkBase({ className, noColor, href, blank, isA, noUnderline, preventDefault, children }) {
  className = cn({ 'text-link': !noColor, 'no-underline hover:no-underline': noUnderline }, className)
  const target = blank ? '_blank' : ''
  if (blank || isA) {
    return (<a className={ className } href={ href } target={ target } rel='noreferrer'>{ children }</a>)
  }

  const onClick = e => {
    if (!preventDefault) return
    e.preventDefault()
  }

  return (
    <Link href={ href } passHref>
      <a className={ className } onClick={ onClick }>{ children }</a>
    </Link>
  )
}
